











































































































































































































































































































































































.radioGroupEntity::v-deep {
  .v-input--radio-group__input {
    justify-content: space-between;
  }
}
